import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import NavBar from "../../components/NavBar";
import { Container, Typography, Button, useMediaQuery} from "@mui/material";
import divider from '../../assets/divider_01.png' 
import {Swiper, SwiperSlide} from "swiper/react"
import 'swiper/css';
import { useTheme } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import image1 from '../../assets/sodarpage/11_E3P5_slide_img_01.jpg'
import image1 from '../../assets/sodarpage/sodar1.webp'
import image2 from '../../assets/sodarpage/sodar2.webp'
import image4 from '../../assets/sodarpage/sodar4.webp'
import image5 from '../../assets/sodarpage/sodar5.webp'
import { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Scrollbar, Autoplay } from "swiper/modules"
const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        textAlign : 'center',
        marginTop: theme.spacing(15),
        
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    },
    modal:{
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2,4,3),
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)'
    },
    icons: {
        cursor: 'pointer'
    },
    inputMaterial:{
        width: '100%'
    },  

    swiper:{
        width: "100%",
        height: "100%",
        padding: 0
    },
      
    swiperSlide: {
        textAlign: "center",
        fontSize: "18px",
        background: "#fff",
        padding: 0,
        lineHeight: 0 ,
        /* Center slide text vertically */
        display: "flex",
        justifContent: "center",
        alignItems: "center",
    },
      
    swiperSlidImg: {
        display: "flex",
        width: "100%",
        height: "100%",
        
    },
    footer: {
        backgroundColor: '#333', // Color de fondo del footer
        color: '#fff', // Color del texto del footer
        padding: theme.spacing(2), // Espaciado interno del footer
        textAlign: 'center', // Alineación del texto en el centro
        marginTop: 'auto', // Coloca el footer al final de la página
    },

}))

export default function Sodar(){
    const classes = useStyles()
    const imageFile = new Image();
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        // Esto asegura que al cargar la nueva página, se inicie desde arriba
        window.scrollTo(0, 0);
    }, []);

    return(
        <div>
        
            <Container sx = {{marginTop:2}} maxWidth = 'xl' >
                <NavBar/>
            </Container>
            <Container sx = {{marginTop:6}} maxWidth = 'lg'>
                <Typography variant="h4" align="left" sx = {{textTransform: 'uppercase', fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}  mt={10}  >
                    UNIDAD SODAR 
                </Typography >   
                <br></br>
                <br></br>
                <Grid container>
                    <Grid xs = {12} sm = {10} xl={12}>
                        <Typography  variant="h5"  sx = {{ fontFamily: 'Open Sans, sans-serif'}} color={'grey'} mb={6}>
                            Ofrecemos el sistema SODAR (Sonic Detection and Ranging) para medir la velocidad y dirección del viento en diversas alturas. 
                            Este dispositivo proporciona datos precisos y en tiempo real, esenciales para la gestión de parques eólicos, estudios meteorológicos y proyectos de infraestructura.
                        </Typography>
                    </Grid>
                    
                    <Grid xs = {12} sm = {10} xl={12}>
                        <Typography variant="h6"  mb={6} sx = {{ fontFamily: 'Open Sans, sans-serif'}} > 
                            La unidad SODAR está diseñada para ser portátil y fácil de desplegar en múltiples sitios y condiciones. 
                            Esta flexibilidad permite su uso en una variedad de entornos y aplicaciones, adaptándose a sus necesidades específicas. 
                            A pesar de ser una solución rentable comparada con otras tecnologías como los radares meteorológicos, no compromete la calidad y precisión de los datos obtenidos.
                        </Typography>
                    </Grid>
                </Grid>
                 
            </Container>
            <br></br>
            <Swiper
                //effect = {'fade'}
               //grabCursor = {true}
                spaceBetween={isMediumScreen ? 0 : 0} 
                slidesPerView = {isMediumScreen ? 3 : 'auto'}
                scrollbar = {{
                    hide: true
                }}
                autoplay = {{
                    delay: 3000,
                    disableOnInteraction: false,
                    
                }}
                speed={2500}
                modules={[Scrollbar,Autoplay]}
            >
                <SwiperSlide>
                    <LazyLoadImage
                        alt = 'Sodar 1'
                        height= 'auto'
                        style={{ maxWidth: '100%' }}
                        src={image1}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <LazyLoadImage
                        alt = 'Sodar 2'
                        height='auto'
                        style={{ maxWidth: '100%' }}
                        src={image2}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <LazyLoadImage
                        alt = 'Sodar 3'
                        height='auto'
                        style={{ maxWidth: '100%' }}
                        src={image4}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <LazyLoadImage
                        alt = 'Sodar 4'
                        height='auto'
                        style={{ maxWidth: '100%' }}
                        src={image5}
                    />
                </SwiperSlide>
            </Swiper>
            <br></br>
            <br></br>
            <br></br>
            <Container maxWidth='lg'>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} align="center">
                        <Button startIcon={<ArrowBackIcon style={{ fontSize: "3rem" }} />} component='a' href={'/'} style={{color:'black'}}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif', textAlign: 'center', color:'black'}}>
                                home
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} align="center">
                        <Button endIcon={<ArrowForwardIcon style={{ fontSize: "3rem" }} />} component='a' href={'/obrasciviles'} style={{color:'black'}}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif', textAlign: 'center', color:'black' }}>
                                Obras civiles
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            <br></br>
            <br></br>
            <br></br>
            <div align = 'center' style={{marginTop: '15px', marginBottom:'15px'}}>
                <img src = {divider} />
            </div>
            <footer className={classes.footer} >
                <Typography variant='body2' sx = {{fontFamily: 'Open Sans, sans-serif'}}>© 2024 Desamd. Todos los derechos reservados.</Typography>
            </footer>
        </div>
    );
}

/** */