import React from "react";
import { Grid, makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import NavBar from "../../components/NavBar";
import { Container, Typography } from "@mui/material";
import divider from '../../assets/divider_01.png' 
import {Swiper, SwiperSlide} from "swiper/react"
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Scrollbar, Autoplay } from "swiper/modules"
import feria_a from '../../assets/about/feria_a.webp'
import reunion_a from '../../assets/about/reunion_a.webp'
import berlin_a from '../../assets/about/berlin_a.webp'
import Timeline from '@mui/lab/Timeline';
import TimelineItem,  { timelineItemClasses }from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
  } from '@mui/lab/TimelineOppositeContent';



const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        textAlign : 'center',
        marginTop: theme.spacing(15),
        
    },
    text2:{
        textAlign: "left",
        marginTop: theme.spacing(3),
        padding: "10px 20px",
        color: "white",
        [theme.breakpoints.down("sm")]: {
          //fontSize: '2rem', // Ajusta el tamaño de fuente en pantallas pequeñas
          padding: "5px 10px",
        },
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    },
    modal:{
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2,4,3),
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)'
    },
    icons: {
        cursor: 'pointer'
    },
    inputMaterial:{
        width: '100%'
    },
    footer: {
        backgroundColor: '#333', // Color de fondo del footer
        color: '#fff', // Color del texto del footer
        padding: theme.spacing(2), // Espaciado interno del footer
        textAlign: 'center', // Alineación del texto en el centro
        marginTop: 'auto', // Coloca el footer al final de la página
    },

}))

const timeline = [
    {
        year: 1988, text:"DESAMD es fundada por Canio Basilio, como una empresa de Informática."
    },
    {
        year: 1997, text:"DESAMD se constituye como Compañía, con capitales nacionales"
    },
    {
        year: 2007, text:"DESAMD participa en misiones tecnológicas junto a La Cámara Chileno Alemana. Se crea la división de energías renovables con base en Solar Info Centro en la ciudad de Friburgo, Alemania."
    },
    {
        year: 2008, text:"DESAMD es incluido como Partner para Chile y Latinoamérica de la prestigiosa marca AMMONIT GmbH"
    },
    {
        year: 2009, text: "DESAMD realiza proyectos en Antillas Holandesas para los proyectos de Tera Kora y Playa kanoa en Curazao y Vader Piet en Aruba."
    },
    {
        year: 2014, text: "DESAMD Amplia su oferta de equipos incorporando la tecnología Lidar y Sodar para la evaluación de Potencial eólico"
    },
    {
        year: 2019, text: "DESAMD Amplia su oferta de equipos incorporando la tecnología Lidar y Sodar para la evaluación de Potencial eólico"
    },
    

]
export default function Acercade(){
    const classes = useStyles()
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return(
        <div>
            <Container sx = {{marginTop:2}} maxWidth = 'xl'>
                <NavBar/>
            </Container>
            <br></br>
            <br></br>
            <div>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    grabCursor = {true}
                    scrollbar = {{
                        hide: true
                    }}
                    autoplay = {{
                        delay: 3000,
                        disableOnInteraction: false,
                        
                    }}
                    speed={3000}
                    modules={[Scrollbar,Autoplay]}      
                >
                    
                    <div >
                        <SwiperSlide>
                            <div className="image-container">
                                <LazyLoadImage
                                    alt='Feria Berlin'
                                    height={'auto'}
                                    width={'100%'}
                                    src={berlin_a}
                                />
                            
                                <div className="image-overlay">
                                    <Typography 
                                        variant= 'h2' 
                                        className={classes.text2} 
                                        sx = {{fontSize: isSmallScreen ? '2rem' : '3rem', fontFamily: 'Open Sans, sans-serif'}}
                                    >
                                        NUESTRA HISTORIA
                                    </Typography>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="image-container">
                                <LazyLoadImage
                                    alt='Feria'
                                    height={'auto'}
                                    width={'100%'}
                                    src={feria_a}
                                />
                                <div className="image-overlay">
                                    <Typography 
                                        variant= 'h2' 
                                        className={classes.text2} 
                                        sx = {{fontSize: isSmallScreen ? '2rem' : '3rem', fontFamily: 'Open Sans, sans-serif'}}
                                    >
                                        NUESTRA HISTORIA
                                    </Typography>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="image-container">
                                <LazyLoadImage
                                    alt='Reunion'
                                    height={'auto'}
                                    width={'100%'}
                                    src={reunion_a}
                                />
                                <div className="image-overlay">
                                    <Typography 
                                        variant= 'h2' 
                                        className={classes.text2} 
                                        sx = {{fontSize: isSmallScreen ? '2rem' : '3rem', fontFamily: 'Open Sans, sans-serif'}}
                                    >
                                        NUESTRA HISTORIA
                                    </Typography>
                                </div>
                            </div>
                        </SwiperSlide>

                    </div>
                
                </Swiper>
            </div>
            <Container  maxWidth = 'xl'>
                <br></br>
                <br></br>

                <div align = 'center' >
                    <img src = {divider} />
                </div>
   
                <br></br>
                <br></br>
                <Swiper
                    align = 'center'
                    centeredSlides = {true}
                    slidesPerView={'auto'}
                    autoplay = {{
                        delay: 4000,
                        disableOnInteraction: false
                    }}
                    modules={[Autoplay]}
                >
                    <SwiperSlide>
                        <Typography variant="h5" mb = {7} mt={4}  align="center" paragraph = {true} color={'grey'} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            DESAMD es una compañía especializada en el diseño e implementación de campañas de Evaluación de Potencial Eólico y Solar. 
                            Apoyamos directamente a los desarrolladores en las etapas iniciales de cada proyecto.
                        </Typography>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Typography variant="h5" mb = {7} mt={4}  align="center" paragraph = {true} color={'grey'} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            DESAMD es un recurso estratégico donde cada profesional que lo compone asume personalmente la responsabilidad y 
                            el compromiso de cada actividad, con un objetivo definido para cada etapa del proyecto.
                        </Typography>
                    </SwiperSlide>
                </Swiper>
                

                <br></br>
                <br></br>

                <Grid container justifyContent="center" style={{  marginTop: '50px' }}>
                    <Grid item xs={12} md={6} style={{  marginTop: '10px' }}>
                        <Typography variant="body1" className = { classes.text3 } mb = {8} mr={4} ml={4} align="center" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Desde Abril de 1988 hemos recorrido el camino que nos ha dado la madurez necesaria para afrontar los nuevos desafíos de un mundo globalizado.
                            <br></br>
                            <br></br>
                            Participamos en la industria de la tecnología, poniéndola al servicio de los usuarios para un desarrollo sustentable y armónico en una sociedad moderna.
                            <br></br>
                            <br></br>
                            El tamaño de nuestra compañía, su rápida adecuación a los cambios, nos permitirán seguir desarrollando toda nuestra Creatividad, Innovación permanente para crea valor, sin límite de fronteras o barreras culturales.
                            <br></br>
                            <br></br>
                            La transparencia, confianza y honestidad de nuestros profesionales, son los valores que le imprimen el sello a nuestros productos y servicios.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}> 
                        <Timeline 
                            sx={{
                                [`& .${timelineOppositeContentClasses.root}`]: {
                                flex: 0.2,
                                },
                            }}
                        >
                            {timeline.map((obj) =>(
                                <TimelineItem>
                                    <TimelineOppositeContent color={'primary'}>
                                        {obj.year}
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot/>
                                        <TimelineConnector/>
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        {obj.text}
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </Grid>
                </Grid>
            </Container> 
            <br></br>
            <br></br>
            <div align = 'center' style={{marginTop: '15px', marginBottom:'15px'}}>
                <img src = {divider} />
            </div>
            <footer className={classes.footer} >
                <Typography variant='body2' sx = {{fontFamily: 'Open Sans, sans-serif'}}>© 2024 Desamd. Todos los derechos reservados.</Typography>
            </footer>
        </div>
        
    );
}

/**<Grid container justifyContent="center">
                    <Grid item xs={12} md={4}>
                        <Typography variant="body1" className = { classes.text3 } mb = {8} mr={4} ml={4} align="center" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            DESAMD es una compañía especializada en el diseño e implementación de campañas de Evaluación de Potencial Eólico y Solar. Apoyamos directamente a los desarrolladores en las etapas iniciales de cada proyecto.
                        </Typography>
                    </Grid>
                    <Grid items xs={12} md={6}>
                        <Typography variant="body1" className = { classes.text3 } mb = {8}  mr={4} ml={4} align="center" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            DESAMD es un recurso estratégico donde cada profesional que lo compone asume personalmente la responsabilidad y el compromiso de cada actividad, con un objetivo definido para cada etapa del proyecto.
                        </Typography>
                    </Grid>

                </Grid>
             */