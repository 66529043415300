import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useNavigate} from 'react-router-dom';
import { Tab, TextField, makeStyles,} from "@material-ui/core";
import Box from '@mui/material/Box';
import NavBarAdm from "../../components/NavBarAdm";
import EditIcon from '@mui/icons-material/Edit';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Button,
  Modal,
  MenuItem,
  Grid,
  
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from '@mui/material/TablePagination';
import { tableCellClasses } from '@mui/material/TableCell';


const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        marginLeft: 155,
        marginTop: 30
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    },
    modal:{
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2,4,3),
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)'
    },
    icons: {
        cursor: 'pointer'
    },
    inputMaterial:{
        width: '100%'
    },


}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#000443',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

export default function ClientTable() {

    let [data, setData] = useState([]);
    const [clientes, setCliente] = useState([])
    const history = useNavigate();
    const classes = useStyles()
    const [filterValueByNumber, setFilterValueByNumber] = useState('');
    const [filterValue, setFilterValue] = useState('')
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const startIndex = page * rowsPerPage;
    //const endIndex = startIndex + rowsPerPage;
    const[modalEdit, setModalEdit] = useState(false)
    const[clienteSeleccionado, setClienteSeleccionado] = useState({
        nombre: '',
        id:'',
        contacto:'',
        tipo:'',
        correo:'',
        dominio:''
    })

    /*const axiosClient = axios.create({
        baseURL:'http://localhost:4000'
    })*/
    const axiosClient = axios.create({
        baseURL:'https://zeroth.cl:4000'
    })
    const getClientes = async()=>{
        await axiosClient.get('/api/clientes/getClientes')
        .then(response=>{
            console.log(response.data)
            setCliente(response.data)
        })
    }

    useEffect(()=>{
        getClientes()
    },[])


    const ids =  clientes.map(item => item)
    const handleChange=e=>{ //alamcenamos lo que se escribe en el textfield
        const{name, value}=e.target; //name es una propiedad que le di a cada textfield mas abajo
        if(name!==""){
            setClienteSeleccionado(prevState=>({
                ...prevState,
                [name]:value
            }))
        }

    }
    const abrirCerrarModalEdit =() =>{
        console.log(clienteSeleccionado)

        setModalEdit(!modalEdit); //abre o cierra el modal
    }

    const handleSeleccionarCliente = (cliente) =>{
        console.log(cliente)
        setClienteSeleccionado(cliente)
        console.log(clienteSeleccionado)
        abrirCerrarModalEdit()
    }
    const editarCliente = async() =>{
        try{
          
            const response = await axiosClient.put('/api/clientes/updatecliente/'+clienteSeleccionado.id, clienteSeleccionado)

            if(response.status === 200){
                var dataNueva = data.map((cliente) => {
                    if(clienteSeleccionado.id === cliente.id){
                        return{
                            ...cliente,
                            nombre: clienteSeleccionado.nombre,
                            id:clienteSeleccionado.id,
                           
                        }
                    }
                    return cliente
                })
                setData(dataNueva)
                abrirCerrarModalEdit()
                getClients();
            }else{
                console.error("Error en la respuesta: ",response.status)
            }
        }catch(error){
            console.error("Error al editar cliente: ", error)
        }


    }
    const bodyEdit = (
        
        <div className= {classes.modal}>
            <h3>Editar Cliente</h3>
            <br/><br/>
            <TextField name='nombre' className={classes.inputMaterial} label='Nombre' onChange={handleChange}  value={clienteSeleccionado && clienteSeleccionado.nombre} />
            <br/><br/>
            <TextField name='contacto' className={classes.inputMaterial} label='Contacto' onChange={handleChange} value={clienteSeleccionado && clienteSeleccionado.contacto}/>
            
            <TextField 
                name='padre' 
                className={classes.inputMaterial} 
                label='Padre'
                onChange={handleChange} 
                select
                id="outlined-required-select-currency"
                variant='standard'
                margin='normal'
                value={clienteSeleccionado && clienteSeleccionado.padre}
            >
                {ids.map((id) => (
                    <MenuItem key={id} value={id.id}>
                        {id.nombre}
                    </MenuItem>
                ))}
            </TextField>
            
            <TextField name='correo'  className={classes.inputMaterial} label='Correo' onChange={handleChange}  value={clienteSeleccionado && clienteSeleccionado.correo} />
            <TextField name='dominio' className={classes.inputMaterial} label='Dominio' onChange={handleChange}  value={clienteSeleccionado && clienteSeleccionado.dominio} />
            <br/><br/>
            <TextField name='id' className={classes.inputMaterial} disabled label='ID' onChange={handleChange} value={clienteSeleccionado && clienteSeleccionado.id}/>
            <br/><br/>
            <div align = 'right'>
                <Button onClick={()=>editarCliente()}>Editar Cliente</Button>
                <Button onClick={()=>abrirCerrarModalEdit()}>Cancelar</Button>
            </div>
        </div>
    )


    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        // Si la página actual está fuera de rango después de cambiar el número de filas por página,
        // ajustamos la página para que esté dentro del rango
        setPage(Math.min(page, Math.ceil(filteredData.length / newRowsPerPage) - 1));
    };



    const filteredData = data.filter(cliente =>
        cliente.nombre.toLowerCase().includes(filterValue.toLowerCase()) &&
        (!filterValueByNumber || cliente.id === parseInt(filterValueByNumber))
    );
    

    const handleFilterChange = (event) => {
        setFilterValue(event.target.value);
        setPage(0);
        
    };
    const handleIdFilterChange = (event) =>{
        setFilterValueByNumber(event.target.value)
        setPage(0);
    }
    const getClients = async()=>{
        await axiosClient.get('/api/clientes/getclientes')
        .then(response=>{
            setData(response.data)
        })
    }

    useEffect (() =>{
        getClients();

    },[])

    
    
    return (
        
        <div>
            
            <Container sx = {{marginTop:2}} maxWidth='xl'>
                <NavBarAdm/>
            </Container>
            <Container sx = {{marginTop:8, marginBottom:4}} maxWidth='lg'>
                
                <h1 className = { classes.text3 }>
                    <Typography variant="h3" align="center" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}} mb={6}>
                        Listado de Clientes
                    </Typography>   
                </h1>

                <Grid container spacing={4}>
                    <Grid item xs = {12} sm = {6} alignItems="center">
                        <TextField
                            value = {filterValue}
                            onChange={handleFilterChange}
                            variant='outlined'
                            margin='dense'
                            placeholder = 'Filtar por nombre de cliente'
                            fullWidth
                            InputProps={{
                                startAdornment:(
                                    <InputAdornment position='start'>
                                        <SearchIcon/>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs = {12} sm = {6} alignItems="center">
                        <TextField
                            value = {filterValueByNumber}
                            onChange={handleIdFilterChange}
                            variant='outlined'
                            margin='dense'
                            placeholder = 'Filtar por ID de cliente'
                            fullWidth
                            InputProps={{
                                startAdornment:(
                                    <InputAdornment position='start'>
                                        <SearchIcon/>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                
                <br></br>
                <TableContainer component={Paper} >
                    <Table size="small">
                        <TableHead>
                        <TableRow >
                            <StyledTableCell align='left' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Nombre del Cliente</StyledTableCell>
                            <StyledTableCell align='center'sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>ID del Cliente</StyledTableCell>
                            <StyledTableCell align='center'sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Tipo</StyledTableCell>
                            <StyledTableCell align='right'sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Padre</StyledTableCell>
                            <StyledTableCell align='center'sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Contacto</StyledTableCell>
                            <StyledTableCell align='center'sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Correo Electronico</StyledTableCell>
                            
                            <StyledTableCell align='center'sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Acciones</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project) => (
                                <StyledTableRow key={project.id_proyecto}>
                                    <StyledTableCell align='left' component='th' scope='row' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.nombre}</StyledTableCell>
                                    <StyledTableCell align='center'sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.id}</StyledTableCell>
                                    <StyledTableCell align='center'sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.tipo}</StyledTableCell>
                                    <StyledTableCell align='right'sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.padre}</StyledTableCell>
                                    <StyledTableCell align='center'sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.contacto}</StyledTableCell>
                                    <StyledTableCell align='center'sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.correo}@{project.dominio}</StyledTableCell>
                                    
                                    <StyledTableCell align='center'sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                        <Button  onClick={()=>handleSeleccionarCliente(project)}>
                                            <EditIcon className = {classes.icons}/>
                                        </Button>
                                        
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
      
                    </Table>
                </TableContainer>
                <Modal
                    open = {modalEdit}
                    onClose = {abrirCerrarModalEdit}
                >
                    {bodyEdit}
                </Modal>
                <TablePagination
                    rowsPerPageOptions={[8, 10, 25, 50, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />                
                <Box
                    marginTop={5}
                    marginLeft={'30%'}
                    marginRight={'30%'}
                    sx={{ fontFamily: 'Open Sans, sans-serif' }}
                >
                    <Button 
                        className={classes.button}
                        type = "button"
                        marginTop = {10}
                        size="large"
                        fullWidth
                        variant="contained"
                        href='/clientes/agregarcliente'
                        startIcon = {<AddIcon/>}
                        sx={{ fontFamily: 'Open Sans, sans-serif' }}
                    >
                        <Typography
                        >
                            Agregar Cliente
                        </Typography>
                    </Button>
                </Box>

            </Container>
        </div>
    );
}

