import React, { useState, useEffect } from "react";
import {Marker, Popup, useMapEvents} from 'react-leaflet'
import axios from 'axios';
import L from 'leaflet';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Typography } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";

// Función para crear el icono personalizado
const customIcon = (number) => L.divIcon({
    html: `<div class="custom-marker"><span>${number}</span></div>`,
    className: 'custom-marker-icon'
});
const regiones2 = {
    AP: 'Región de Arica y Parinacota',
    TA: 'Región de Tarapacá',
    AN: 'Región de Antofagasta',
    AT: 'Región de Atacama',
    CO: 'Región de Coquimbo',
    VA: 'Región de Valparaíso',
    RM: 'Región Metropolitana de Santiago',
    LI: "Región del Libertador General Bernardo O'Higgins",
    ML: 'Región del Maule',
    NB: 'Región de Ñuble',
    BI: 'Región del Biobío',
    AR: 'Región de La Araucanía',
    LR: 'Región de Los Ríos',
    LL: 'Región de Los Lagos',
    AI: 'Región de Aysén del General Carlos Ibáñez del Campo',
    MA: 'Región de Magallanes y de la Antártica Chilena'
}
const obtenerNombreRegion = (abreviatura) => {
    return regiones2[abreviatura] || abreviatura;
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgba(0, 4, 67, 1)',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));
export default function Markers(){
    const [regiones, setRegiones] = useState([])
    const history = useNavigate()
    const [activeRegion, setActiveRegion] = useState(null); // Estado para la región activa
    const [regionSeleccionada, setRegionSeleccionada] = useState(null)
    const [proyectosRegion, setProyectosRegion] = useState([])

    /*const axiosClient = axios.create({
        baseURL:'http://localhost:4000'
    })*/
    const axiosClient = axios.create({
        baseURL:'https://zeroth.cl:4000'
    })

    const getRegiones = async() => {
        await axiosClient.get('/api/proyectos/getregiones')
        .then(response =>{
            console.log(response.data)
            setRegiones(response.data)
        })
    }
    useEffect(() => {
        getRegiones()
    },[])

    const getProyectosRegion = async(selectedRegion) =>{
        await axiosClient.get('/api/proyectos/getproyectosregion/'+regionSeleccionada)
        .then(response =>{
            setProyectosRegion(response.data)
            setRegionSeleccionada(selectedRegion)
            console.log(regionSeleccionada)
        })
    }
    useEffect(() =>{
        getProyectosRegion(regionSeleccionada);
    },[regionSeleccionada])

    const handleMarkerClick = (region) => {
        /*history(`/getproyectosregion/${region.region}`)*/
        getProyectosRegion(region.region)
    }
    
    // Función para manejar mouseover en el marcador
    const handleMouseOver = (region) => {
        setActiveRegion(region);
    };

    // Función para manejar mouseout en el marcador
    const handleMouseOut = () => {
        setActiveRegion(null);
    };
    // Hook para manejar eventos de ratón en el mapa
    console.log(proyectosRegion)

    // Definimos media query para detectar pantallas pequeñas
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    return(
        <div>
            {regiones.map((region, i) =>(
                <Marker 
                    position={[region.Latitud,region.Longitud]} 
                    key={i}
                    icon={customIcon(region.cantidad)}
                    eventHandlers={{
                        click: ()=>handleMarkerClick(region),
                        
                    }}
                >
                    <Popup minWidth={isSmallScreen ? 250 : 400}>
                        <Typography align="center"> 
                            {obtenerNombreRegion(region.region)}
                        </Typography>
                        <TableContainer component={Paper} sx={{ maxHeight: 440, minWidth: isSmallScreen ? 250 : 400}}>
                            <Table stickyHeader size="small" >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Proyecto</StyledTableCell>
                                        <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Lugar</StyledTableCell>                                    
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {proyectosRegion.map((proyecto) =>(
                                        <StyledTableRow>
                                            <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{proyecto.id_proyecto}</StyledTableCell>
                                            <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{proyecto.subtitulo_proyecto}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Popup>

                </Marker>
            ))}

        </div>

    );
}