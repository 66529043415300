import React from 'react';
import NavBar from '../../components/NavBar';
import { makeStyles,useMediaQuery, useTheme } from "@material-ui/core";
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, Box, Button, listItemTextClasses } from "@mui/material";
import {Swiper, SwiperSlide} from "swiper/react"
import divider from '../../assets/divider_01.png'
import { Scrollbar, Autoplay, Mousewheel, Keyboard } from "swiper/modules"
import 'swiper/css/scrollbar';
import 'swiper/css';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import panoramica from '../../assets/homepage/panoramica_a.webp'
import torre from '../../assets/homepage/torre_a.webp'
import antartida from '../../assets/homepage/antartida_a.webp'
import metsolar from '../../assets/homepage/metSolar_a.webp'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useEffect } from 'react';

const useStyles = makeStyles(theme=>({
  
    text:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(5),
        [theme.breakpoints.down('sm')]:{
            marginTop:theme.spacing(5)
        }
    },
    text2:{
        textAlign : 'left',
        marginTop: theme.spacing(3),
        padding: '10px 20px',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            //fontSize: '2rem', // Ajusta el tamaño de fuente en pantallas pequeñas
            padding: '5px 10px'
        }
    },
    img:{
        flex: 1,
        height: '100%',
        width: '60px',
        transform: 'skew(5deg)',
        cursor: 'pointer',
        margin: '0 2px',
        transition: 'all 0.3s',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        border: '1px solid #222',
        position: 'relative'
    },
    text3:{
        textAlign: 'center',
        marginBottom: theme.spacing(15),
        marginLeft:theme.spacing(15),
        [theme.breakpoints.down('sm')]:{
            marginLeft:theme.spacing(15)
        }
    },

    imgn2:{
        width: "360px"
    },
    icons: {
        cursor: 'pointer'
    },
    footer: {
        backgroundColor: '#333', // Color de fondo del footer
        color: '#fff', // Color del texto del footer
        padding: theme.spacing(2), // Espaciado interno del footer
        textAlign: 'center', // Alineación del texto en el centro
        marginTop: 'auto', // Coloca el footer al final de la página
    },

    
    
}))
export default function HomeTest(){
    const classes = useStyles()
    const theme = useTheme();
    const history = useNavigate();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleImageClick = (link) => {
        history(`/${link}`)
    }


    useEffect(() => {
        // Esto asegura que al cargar la nueva página, se inicie desde arriba
        window.scrollTo(0, 0);
    }, []);



    return(
        <div>
            <Container sx = {{marginTop:2}} maxWidth = 'xl'>
                <NavBar/>

            </Container>
                <br></br>
                <br></br>
                <div>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        grabCursor = {true}
                        scrollbar = {{
                            hide: true
                        }}
                        autoplay = {{
                            delay: 3000,
                            disableOnInteraction: false,
                            
                        }}
                        speed={3000}
                        modules={[Scrollbar,Autoplay]}
                    >
                        <SwiperSlide >
                            <div className="image-container">
                                <LazyLoadImage
                                    alt='Limpieza Sitio PE Aura del Sur'
                                    height={'auto'}
                                    width={'100%'}
                                    src={panoramica}
                                />
                               {/*<img src = {panoramica} style={{margin: '0px', width: '100%', height: 'auto', borderRadius: '0px' }}/>*/}
                                <div className="image-overlay">
                                    <Typography 
                                        variant= 'h2' 
                                        className={classes.text2} 
                                        sx = {{fontSize: isSmallScreen ? '2rem' : '3rem', fontFamily: 'Open Sans, sans-serif'}} 
                                    >
                                        PARTICIPAMOS EN CADA PROYECTO DESDE EL INICIO
                                    </Typography>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide >
                            <div className="image-container">
                                <LazyLoadImage
                                    alt='Torre e Instrumentos'
                                    height={'auto'}
                                    width={'100%'}
                                    src={torre}
                                />
                                <div className="image-overlay">
                                    <Typography 
                                        variant= 'h2' 
                                        className={classes.text2} 
                                        sx = {{fontSize: isSmallScreen ? '2rem' : '3rem', fontFamily: 'Open Sans, sans-serif'}}
                                    >
                                        DISEÑAMOS Y SUMINISTRAMOS INFRAESTRUCTURA
                                    </Typography>
                                </div>
                            </div>
                            {/*<img src = {torre} style={{margin: '0px', width: '100%', height: 'auto', borderRadius: '0px' }}/>*/}
                        </SwiperSlide>

                        <SwiperSlide >
                            <div className='image-container'>
                                <LazyLoadImage
                                    alt='Equipo Desamd'
                                    height={'auto'}
                                    width={'100%'}
                                    src={antartida}
                                />
                                <div className='image-overlay'> 
                                    <Typography 
                                        variant= 'h2' 
                                        className={classes.text2} 
                                        sx = {{fontSize: isSmallScreen ? '2rem' : '3rem', fontFamily: 'Open Sans, sans-serif'}}
                                    >
                                        PROYECTOS EN CHILE Y EL EXTRANJERO
                                    </Typography>
                                </div>
                            </div>

                            {/*<img src = {nosotros} style={{margin: '0px', width: '100%', height: 'auto', borderRadius: '0px' }}/>*/}
                        </SwiperSlide>
                    </Swiper>
                    <Swiper
                        
                        align = 'center'
                        centeredSlides = {true}
                        slidesPerView={1}
                        autoplay = {{
                            delay: 4000,
                            disableOnInteraction: false
                        }}
                        
                        modules={[Autoplay]}
                    >
                        <SwiperSlide>
                            <Typography variant="h4" align="center" mt={5}paragraph sx = {{fontWeight: 'bold',fontFamily: 'Open Sans, sans-serif'}}  >
                                Infraestructura, equipamiento y servicios para ERNC.
                            </Typography>

                            <Typography variant="h4" align="center" paragraph sx = {{fontFamily: 'Open Sans, sans-serif'}}>
                                Nuestra especialidad es el diseño e implementación 
                            </Typography>

                            <Typography variant="h4" align="center" paragraph sx = {{fontFamily: 'Open Sans, sans-serif'}}>
                                de campañas para evaluación de potencial 
                            </Typography>

                            <Typography variant="h4" align="center" paragraph sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>
                                Eólico y Solar 
                            </Typography>

                            <Typography variant="h4" align="center" paragraph sx = {{fontFamily: 'Open Sans, sans-serif'}} >
                                con fines de generacion eléctrica.
                            </Typography>
                        </SwiperSlide>
                        <SwiperSlide >
                            <Typography variant="h4" align="center" mt={5}paragraph sx = {{fontWeight: 'bold',fontFamily: 'Open Sans, sans-serif'}}  >
                                Desde 1988 hemos recorrido 
                            </Typography>

                            <Typography variant="h4" align="center" paragraph sx = {{fontFamily: 'Open Sans, sans-serif'}}>
                                el camino que nos ha permitido construir
                            </Typography>

                            <Typography variant="h4" align="center" paragraph sx = {{fontFamily: 'Open Sans, sans-serif'}}>
                                una trayectoria avalada en numerosos proyectos 
                            </Typography>

                            <Typography variant="h4" align="center" paragraph sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>
                                desarrollados en Chile y el extranjero.
                            </Typography>

                        </SwiperSlide>
                        <SwiperSlide >
                            <Typography variant="h4" align="center" mt={5}paragraph sx = {{fontFamily: 'Open Sans, sans-serif'}}  >
                                Nuestra propuesta está basada
                            </Typography>

                            <Typography variant="h4" align="center" paragraph sx = {{fontWeight: 'bold',fontFamily: 'Open Sans, sans-serif'}}>
                                en un compromiso personal y acciones concretas,
                            </Typography>

                            <Typography variant="h4" align="center" paragraph sx = {{fontFamily: 'Open Sans, sans-serif'}}>
                                para asegurar la correcta implementación
                            </Typography>

                            <Typography variant="h4" align="center" paragraph sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>
                                en un tiempo definido "On Time"
                            </Typography>
                            <Typography variant="h4" align="center" paragraph sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>
                                y en el lugar indicado "On Site".
                            </Typography>

                        </SwiperSlide>

                    </Swiper>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom:'7px'}}>
                        <Typography align="center" sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Conoce Nuestra Historia
                        </Typography>
                        <div>
                            <Button href='/acercade' color='inherit'>
                                <ArrowCircleRightRoundedIcon className={classes.icons}/>
                            </Button>
                        </div>
                    </div>
                    <div align = 'center' style={{marginTop: '15px', marginBottom:'15px'}}>
                        <img src = {divider} />
                    </div>
                    <div >
                        <div className='img-accordion'>
                            <div className='img img1' onClick={() => history('/equipamientoDeteccionRemotaLidarSodar')}>
                                <p>Sodar y Lidar</p>
                            </div>
                            <div className='img img2' onClick={() => history('/obrasciviles')}>
                                <p>Obras Civiles</p>
                            </div>
                            <div className='img img3' onClick={() => history('/montaje')}>
                                <p>Suministro y Montaje</p>
                            </div>
                            <div className='img img4' onClick={() => history('/equipamiento')}>
                                <p>Sensores y Accesorios</p>
                            </div>
                            <div className='img img5' onClick={() => history('/equipamientoProyectosSolaresEstacionesFijasYseguimiento')}>
                                <p>Proyectos Solares</p>
                            </div>

                            
                        </div>
                    </div>
                    <div align = 'center' style={{marginTop: '15px', marginBottom:'15px'}}>
                        <img src = {divider} />
                    </div>
                </div>
                <footer className={classes.footer}>
                    <Typography variant='body2' sx = {{fontFamily: 'Open Sans, sans-serif'}}>© 2024 Desamd. Todos los derechos reservados.</Typography>
                </footer>
            </div>
    );
}

