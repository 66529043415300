import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, makeStyles,} from "@material-ui/core";
import Box from '@mui/material/Box';
import NavBarAdm from "../../components/NavBarAdm";
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Cookies from 'js-cookie';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Button,
  Tooltip,
  Grid,
  MenuItem
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from '@mui/material/TablePagination';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import PrintIcon from '@mui/icons-material/Print';

const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        marginLeft: 155,
        marginTop: 30
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    }


}))
function visible(visible){
    let value = ''
    if(visible === 1){
        value = 'SI'
    }
    else{
        if(visible === 0){
            value = "NO"
        }
    }
    return value
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#000443',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function ProjectTable() {

    let [data, setData] = useState([]);
    const history = useNavigate();
    const classes = useStyles()
    const [filteredData, setFilteredData] = useState(data)
    const [filterValueByClient, setFilterValueByClient] = useState('')
    const [filterValueByNumber, setFilterValueByNumber] = useState('') 
    const [filterValueByType, setFilterValueByType] = useState('') 
    const [isAuth, setIsAuth] = useState(false)
    const [page, setPage] = useState(0);
    const [clientes, setCliente] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


    const handlePrint = () => {
        window.print();
    };
    

    // Espera a que la página se cargue completamente

    useEffect(()=> {
        const filterByNumber = project => project.num_proyecto && project.num_proyecto.includes(filterValueByNumber);
        const filterByClient = project => project.nombre_cliente && project.nombre_cliente.includes(filterValueByClient);
        const filterByType = project => project.tipo_proyecto && project.tipo_proyecto.includes(filterValueByType)
        setFilteredData(
            data.filter(project => filterByClient(project) && filterByNumber(project) && filterByType(project))
        )
    }, [filterValueByNumber, filterValueByClient, filterValueByType, data])



    const handleClientFilterChange = (event) =>{
        setFilterValueByClient(event.target.value)
    }

    const handleNumberFilterChange = (event) =>{
        setFilterValueByNumber(event.target.value)
    }

    const handleTypeFilterChange = (event) =>{
        setFilterValueByType(event.target.value)
    }
    
    /*const axiosClient = axios.create({
        baseURL:'http://localhost:4000'
    })*/

    const axiosClient = axios.create({
        baseURL:'https://zeroth.cl:4000'
    })

    const visibleData = filteredData.slice(startIndex, endIndex)

    const getProjects = async()=>{
        await axiosClient.get('/api/proyectos/getproyectosyclientes')
        .then(response=>{
            setData(response.data)
            console.log(response.data)
        }).catch(console.error())
    }
    
    const getClientes = async () => {
        await axiosClient
            .get("/api/clientes/getClientes")
            .then((response) => {
            console.log(response.data);
            setCliente(response.data);
        });
    };

    const checkAuth = async() => {
        await axiosClient
            .get('api/proyectos/checkAuth')
            .then((response)=>{
                console.log(response.data.authenticated)
                setIsAuth(response.data.authenticated)
            })
    }
    
    useEffect(() => {
        getClientes();
        checkAuth();
    }, []);

    useEffect(() => {
        // Verifica si hay una cookie para indicar que ya se redirigió
        const isRedirected = Cookies.get('isRedirected');
        const newWindow=''
        if (!isRedirected) {
          // Redirige automáticamente al usuario a la ruta de autenticación
          if(isAuth){
            //const newWindow = window.open('https://zeroth.cl:4000/api/auth/google', '_blank');
            newWindow = window.open('https://zeroth.cl:4000/api/auth/google', '_blank');
          }

          if (newWindow) {
            // Marca la nueva ventana abierta
            newWindow.opener = null;
            // Establece una cookie para indicar que ya se redirigió
            Cookies.set('isRedirected', 'true');
          }
        }
    }, []);
    


    useEffect (() =>{

        getProjects();

    },[])


    const handleProjectDetails = (projectId) => {
        // Cambia la ruta a la página de detalles del proyecto
        history(`/proyectos/getproyectos/${projectId}`);
    };
    const names = clientes.map((item) => item);
    const tipos = [
        {
            name:'Eolico',
            value: 'Eolico'
        },
        {
            name:'Solar',
            value:'Solar'
        },
        {
            name:'Eolico y Solar',
            value:'Solar y Eolico'
        },
        {
            name:'Docencia',
            value:'Docencia'
        },
        {
            name:'Estudio',
            value:'Estudio'
        },
        {
            name:'Hidraulico',
            value:'Hidraulico'
        },
        {
            name:'Infraestructura',
            value:'Infraestructura'
        },
        {
            name:'Medio Ambiental',
            value:'Medio Ambiental'
        },
        {
            name:'Potencial Fotovoltaico',
            value:'Potencial Fotovoltaico'
        },
        {
            name:'Software',
            value:'Software'
        },
        {
            name:'Otro',
            value:'Otro'
        }

    ]
    return (
        
        <div>
            <br></br>
            <Container sx = {{marginTop:2}} maxWidth = 'xl'>
                <NavBarAdm/>
            </Container>
            <Container sx = {{marginTop:8, marginBottom:4}}  maxWidth = 'lg'>
                
                <h1 className = { classes.text3 }>
                    <Typography variant="h3" align="center" sx = {{ fontFamily: 'Open Sans, sans-serif'}} mb={6}>
                        Listado de Proyectos
                    </Typography>   
                </h1>
                <Grid container spacing={4}>
                    <Grid item xs = {12} sm = {4} alignItems="center">
                       
                        <TextField
                            value = {filterValueByNumber}
                            onChange={handleNumberFilterChange}
                            variant='outlined'
                            margin='dense'
                            
                            placeholder = 'Filtar por numero de proyecto'
                            sx={{ fontFamily: 'Open Sans, sans-serif' }}
                            fullWidth
                            InputProps={{
                                startAdornment:(
                                    <InputAdornment position='start'>
                                        <SearchIcon/>
                                    </InputAdornment>
                                )
                            }}
                        />                        
                    </Grid>
                    <Grid item xs = {12} sm = {4} alignItems="center">
                        
                        <TextField
                            value = {filterValueByClient}
                            onChange={handleClientFilterChange}
                            variant='outlined'
                            margin='dense'
                            select
                            
                            label = 'Filtrar por Cliente'
                            sx={{ fontFamily: 'Open Sans, sans-serif' }}
                            fullWidth
                            InputProps={{
                                startAdornment:(
                                    <InputAdornment position='start'>
                                        <SearchIcon/>
                                    </InputAdornment>
                                )
                            }}
                        >
                            <MenuItem value=''>
                                TODOS
                            </MenuItem>
                            {names.map((name) =>(
                                <MenuItem key={name} value={name.nombre}>
                                    {name.nombre}
                                </MenuItem>
                            ))}
                        </TextField>                       
                    </Grid>
                    <Grid item xs = {12} sm = {4} alignItems="center">
                       
                        <TextField
                            value = {filterValueByType}
                            onChange={handleTypeFilterChange}
                            variant='outlined'
                            margin='dense'
                            select
                            
                            label = 'Filtrar por Tipo'
                            sx={{ fontFamily: 'Open Sans, sans-serif' }}
                            fullWidth
                            InputProps={{
                                startAdornment:(
                                    <InputAdornment position='start'>
                                        <SearchIcon/>
                                    </InputAdornment>
                                )
                            }}
                        >
                            <MenuItem value=''>
                                TODOS
                            </MenuItem>
                            {tipos.map((tipo) =>(
                                <MenuItem key={tipo} value={tipo.value}>
                                    {tipo.name}
                                </MenuItem>
                            ))}
                        </TextField>                       
                   </Grid>
                </Grid>
                <br></br>
                <TableContainer component={Paper} >
                    <Table size="small" >
                        <TableHead>
                        <TableRow>
                            <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Codigo Proyecto</StyledTableCell>
                            <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Status</StyledTableCell>
                            <StyledTableCell align='left' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Cliente</StyledTableCell>
                            <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Proyecto</StyledTableCell>
                            <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Visible</StyledTableCell>
                            <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Acciones</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleData.map((project) => (
                                <StyledTableRow key={project.id_proyecto}>
                                    <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.id_proyecto}</StyledTableCell>
                                    <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.status}</StyledTableCell>
                                    <StyledTableCell align='left' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.nombre_cliente}</StyledTableCell>
                                    <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{project.subtitulo_proyecto}</StyledTableCell>                                    
                                    <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{visible(project.visible)}</StyledTableCell>
                                    <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                        {/* Agrega un botón o enlace para ver detalles del proyecto */}
                                        <Tooltip
                                            title={
                                                <React.Fragment>
                                                    {project.descripcion}
                                                </React.Fragment>
                                            }
                                        >
                                            <Button onClick={() => handleProjectDetails(project.id_proyecto)}>
                                                <InfoTwoToneIcon/>
                                            </Button>
                                        </Tooltip>

                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
      
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[8, 10, 25, 50, 100]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />       
                <Grid container>
                    <Grid item xs = {12} sm = {6}>
                        <Box
                        marginTop={5}
                        marginLeft={'30%'}
                        marginRight={'30%'}
                        >
                            <Button 
                                className={classes.button}
                                type = "button"
                                marginTop = {10}
                                size="large"
                                fullWidth
                                variant="contained"
                                href='/proyectos/agregarproyecto'
                                startIcon = {<AddIcon/>}
                                sx={{ fontFamily: 'Open Sans, sans-serif' }}
                            >
                                <Typography>
                                    Agregar Proyecto
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs = {12} sm = {6}>
                        <Box
                            marginTop={5}
                            marginLeft={'30%'}
                            marginRight={'30%'}
                        
                        >
                            <Button 
                                className={classes.button}
                                type = "button"
                                marginTop = {10}
                                size="large"
                                fullWidth
                                variant="contained"
                                onClick={handlePrint}
                                startIcon = {<PrintIcon/>}
                                sx={{ fontFamily: 'Open Sans, sans-serif' }}
                            >
                                <Typography
                                >
                                    imprimir 
                                </Typography>
                            </Button>
                        </Box>

                    </Grid>
                </Grid>         
                
            </Container>
        </div>
    );
}




