import React from 'react'
import { 
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    
} from '@material-ui/core'


import { Box } from '@mui/system'
import { ListItemButton } from '@mui/material';

export default function NavListDrawer({navLink}) {
    
    return (
        <Box sx={{width:250}}>
            <nav>
                <List>
                    {
                        navLink.map(item =>(
                            <ListItem disablePadding key = {item.title}>
                                <ListItemButton
                                    component = 'a'
                                    href = {item.path}
                                >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText>{item.title}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))
                    }

                </List>
            </nav>
            <Divider/>
        </Box>
    )
}