import React, { useState, useEffect } from 'react';
import { Grid, makeStyles,useTheme, useMediaQuery } from "@material-ui/core";
import NavBar from "../../components/NavBar";
import { Container, Typography,TextField, Button, CircularProgress } from "@mui/material";
import camion_a from '../../assets/contactpage/camion_a.webp'
import divider from '../../assets/divider_01.png' 
import axios from 'axios';
import swal from 'sweetalert'
import { LoadingButton } from "@mui/lab";
import { LazyLoadImage } from "react-lazy-load-image-component";

const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        textAlign : 'center',
        marginTop: theme.spacing(15),
        
    },
    text2:{
        textAlign: "left",
        marginTop: theme.spacing(3),
        padding: "10px 20px",
        color: "white",
        [theme.breakpoints.down("sm")]: {
          //fontSize: '2rem', // Ajusta el tamaño de fuente en pantallas pequeñas
          padding: "5px 10px",
        },
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    },
    modal:{
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2,4,3),
        top: '50%',
        left:'50%',
        transform: 'translate(-50%, -50%)'
    },
    icons: {
        cursor: 'pointer'
    },
    inputMaterial:{
        width: '100%'
    },

    footer: {
        backgroundColor: '#333', // Color de fondo del footer
        color: '#fff', // Color del texto del footer
        padding: theme.spacing(2), // Espaciado interno del footer
        textAlign: 'center', // Alineación del texto en el centro
        marginTop: 'auto', // Coloca el footer al final de la página
    },
}))

export default function Contacto(){
    const classes = useStyles()
    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const handleNombreChange = (event) => {
        setNombre(event.target.value);
    };
    
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    
    const handleMensajeChange = (event) => {
        setMensaje(event.target.value);
    };
    
    const mostrarAlertaCorreoEnviado = () =>{
        swal({
            title:'Correo Enviado Correctamente!',
            icon:'success'
        })
    }

    const mostrarServerError = () => {
        swal({
            title: 'SERVER ERROR',
            icon: 'error'
        })
    }

    const areRequiredFieldsFilled = () => {
        const requiredFields = ["nombre","mail","mensaje"];
        


        if(!nombre || !email || !mensaje){
            return false
        }
        
        return true; // Si todos los campos requeridos están llenos, devuelve true
    };

    /*const axiosClient = axios.create({
        baseURL:'http://localhost:4000'
    })*/

    const axiosClient = axios.create({
        baseURL:'https://zeroth.cl:4000'
    })
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            setLoading(true)

            // Enviar los datos al servidor
            const response = await axiosClient.post('/api/mailer/sendmail', {
            nombre,
            email,
            mensaje,
            });
            setLoading(false)
            // Manejar la respuesta del servidor (puede ser un mensaje de éxito, error, etc.)
            console.log('Respuesta del servidor:', response.data);
            if(response.status === 200){
                mostrarAlertaCorreoEnviado()
                setTimeout(() =>  {window.location.reload(false)}, 2000);
            }
            else{
                mostrarServerError()

                setTimeout(() =>  {window.location.reload(false)}, 2000);
            }   
        
            // Puedes mostrar un mensaje de éxito o redirigir al usuario a una página de confirmación aquí
        } catch (error) {
            // Manejar errores de la solicitud (por ejemplo, problemas de red, errores del servidor, etc.)
            console.error('Error al enviar los datos:', error);
        }
    };
    return(
        <div>
            <Container sx = {{marginTop:2}} maxWidth = 'xl'>
                <NavBar/>  
            </Container>  
            <br></br>
            <br></br>
            <div className='image-container'>
                <LazyLoadImage
                    alt='Camion Descargando'
                    height={'auto'}
                    width={'100%'}
                    src={camion_a}
                />
                <div className='image-overlay'>
                    <Typography
                        variant="h2"
                        className={classes.text2}
                        sx={{
                            fontSize: isSmallScreen ? "1.5rem" : "3rem",
                            fontFamily: "Open Sans, sans-serif",
                        }}
                    >
                        HAGAMOS ALGO EXTRAORDINARIO TRABAJANDO JUNTOS
                    </Typography>
                </div>
            </div>

            <Container maxWidth = 'xl'>
                <br></br>
                <div align = 'center' >
                    <img src = {divider} />
                </div>
                <Typography variant="h4" align="center" sx = {{fontFamily: 'Open Sans, sans-serif'}}mt={6} mb={4}>
                    CONTACTO
                </Typography>
                <br></br>
                
                <Grid container >
                    <Grid item xs={12} md={6} style={{ marginTop:'35px'}}>  
                        <Typography variant="body1" className = { classes.text3 } mb = {8} mr={4} ml={4}  paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Cuéntanos acerca de tu proyecto o iniciativa. Estamos disponibles para analizar tus necesidades y preguntas, y así asegurar una implementación exitosa o continuar con tus campañas de Evaluación de Energía Eólica y Solar.
                            <br></br>
                            <br></br>
                            Nuestro objetivo es brindarte una solución personalizada que cumpla con tus requerimientos y cumpla con los estándares internacionales de calidad técnica.
                        </Typography>
                        <Typography variant="body1" className = { classes.text3 } mr={4} ml={4} align="center" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Dirección: Ruta D-43. Parc 1. N° 3910. Pan de Azucár.
                        </Typography>
                        <Typography variant="body1" className = { classes.text3 } mr={4} ml={4} align="center" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Coquimbo - CHILE
                        </Typography>
                        <Typography variant="body1" className = { classes.text3 }  mr={4} ml={4} align="center" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Telefono: +56 998011939
                        </Typography>
                        <Typography variant="body1" className = { classes.text3 }  mr={4} ml={4} align="center" paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Horario: 9:00 am – 18:00 pm
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}> 
                        <form onSubmit={handleSubmit}>
                            <TextField
                                name='nombre'
                                label="Nombre"
                                fullWidth
                                variant="standard"
                                value={nombre}
                                onChange={handleNombreChange}
                                margin="normal"
                            />
                            <TextField
                                name='mail'
                                label="Email"
                                fullWidth
                                variant="standard"
                                value={email}
                                onChange={handleEmailChange}
                                margin="normal"
                            />
                            <TextField
                                name='mensaje'
                                label="Mensaje"
                                fullWidth
                                variant="standard"
                                multiline
                                rows={5}
                                value={mensaje}
                                onChange={handleMensajeChange}
                                margin="normal"
                            />
                            <br></br>
                            <br></br>
                            <LoadingButton disabled={!areRequiredFieldsFilled()} type="submit" variant="contained" color="primary" fullWidth>
                                {loading?<CircularProgress size={24}/>:"Enviar"}                                
                            </LoadingButton>
                        </form>
                    </Grid>
                </Grid>
                <br></br>
                <br></br>
                <div align = 'center' style={{marginTop: '15px', marginBottom:'15px'}}>
                    <img src = {divider} />
                </div>
            </Container>
            <footer className={classes.footer}>
                <Typography variant='body2' sx = {{fontFamily: 'Open Sans, sans-serif'}}>© 2024 Desamd. Todos los derechos reservados.</Typography>
            </footer>
        
            
        </div>
    );
}