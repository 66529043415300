
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, TextField, Button, MenuItem, Switch, Stack, Modal, CircularProgress} from "@mui/material";
import { Grid, makeStyles } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import NavBarAdm from "../../components/NavBarAdm";
import swal from 'sweetalert'

const useStyles = makeStyles(theme=>({
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        marginLeft: 155,
        marginTop: 30
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    },
    modal: {
        position: 'absolute',
        width: '100%', // Ancho del 80% del contenedor padre
        maxWidth: '400px', // Establece un ancho máximo para la modal
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',  // Establece una altura máxima del 80% de la altura visible

        overflowY: 'auto', // Agrega una barra de desplazamiento vertical si es necesario
    },
    icons: {
        cursor: 'pointer'
    },
    inputMaterial:{
        width: '100%'
    },



}))


function visible(visible){
    let value = ''
    if(visible === 1){
        value = 'SI'
    }
    else{
        if(visible === 0){
            value = "NO"
        }
    }
    return value
}

function status(stat){
    let value = ''
    switch(stat){
        case 'AG':
            value='Agendado'
            return value;
        case 'EJ':
            value='Ejecucion'
            return value;
        case 'FI':
            value = 'Finalizado'
            return value
    }
}

function getDate(date){
    let value = (date.toString()).slice(0,10)

    return value
}

const status2 = [
    {
        value:'AG',
        label:'Agendado'
    },
    {
        value:'EJ',
        label:'Ejecucion'
    },
    {
        value:'FI',
        label:'Finalizado'
    },
]

const visible2 = [
    {
        value:1,
        label:'SI'
    },
    {
        value:0,
        label:'NO'
    }
]

export default function ProjectDetails() {
    const { projectId } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [cliente, setCliente] = useState({})
    const history = useNavigate();
    const classes = useStyles()
    const[modalEdit, setModalEdit] = useState(false)
    const [fecha_ej, setFechaEj] = useState('');
    const [fecha_fi, setFechaFi] = useState('');
    const[proyectoSeleccionado, setProyectoSeleccionado] = useState({})

    const mostrarAlertaEditCorrecto = () =>{
        swal({
            title:'Editado correctamente!',
            icon:'success'
        })
    }

    /*const axiosClient = axios.create({
        baseURL:'http://localhost:4000'
    })*/

    const axiosClient = axios.create({
        baseURL:'https://zeroth.cl:4000'
    })

    console.log(projectId)
    const getProjectDetails = async () => {
        try {
            const response = await axiosClient.get('/api/proyectos/getproyectosID/' + projectId);
            setData(response.data);
            setProyectoSeleccionado(response.data ? response.data[0] : '');
        } catch (error) {
            console.error('Error fetching project details:', error);
        }
    };

    const getClient = async () => {
        if (proyectoSeleccionado && proyectoSeleccionado.id_cliente) {
            try {
                const response = await axiosClient.get('/api/clientes/getclientesid/' + proyectoSeleccionado.id_cliente);
                setCliente(response.data[0]);
            } catch (error) {
                console.error('Error fetching client details:', error);
            }
        }
    };

    useEffect(() => {
        getProjectDetails();
    }, [projectId]);

    useEffect(() => {
        if (proyectoSeleccionado && proyectoSeleccionado.id_cliente) {
            console.log('Proyecto seleccionado:', proyectoSeleccionado);
            getClient();
        }else{
            console.log('no hay proyecto seleccionado')
        }
    }, [proyectoSeleccionado]);

    const handleProjectImages = (projectId) => {
        history(`/proyectos/imagenesproyecto/${projectId}`)
    }
    
    //console.log(data[0])
    //console.log(proyectoSeleccionado)
    const handleChange=e=>{ //alamcenamos lo que se escribe en el textfield
        const{name, value}=e.target; //name es una propiedad que le di a cada textfield mas abajo
        if(name!==""){
            setProyectoSeleccionado(prevState=>({
                ...prevState,
                [name]:value
            }))
        }

    }

    const handleFechaChange = (event) =>{
        const newFecha = event.target.value;

        if (proyectoSeleccionado) {
            if (proyectoSeleccionado.status === 'EJ') {
                setProyectoSeleccionado({ ...proyectoSeleccionado, fecha_ej: newFecha });
            } else if (proyectoSeleccionado.status === 'FI') {
                setProyectoSeleccionado({ ...proyectoSeleccionado, fecha_fi: newFecha });
            }
        }
    } 

    const abrirCerrarModalEdit =() =>{
        console.log(proyectoSeleccionado)

        setModalEdit(!modalEdit); //abre o cierra el modal
    }

    const editarProyecto = async()=>{
        try{
            setLoading(true)
            const response = await axiosClient.put('/api/proyectos/updateproyecto/'+proyectoSeleccionado.id_proyecto, proyectoSeleccionado)
            setLoading(false);
            if(response.status === 200){
                var dataNueva = data.map((proyecto) =>{
                    if(proyectoSeleccionado.id === proyecto.id){
                        return{
                            ...proyecto,
                            titulo_proyecto: proyectoSeleccionado.titulo_proyecto,
                            subtitulo_proyecto: proyectoSeleccionado.subtitulo_proyecto,
                            status: proyectoSeleccionado.status,
                            visible: proyectoSeleccionado.visible,
                            fecha_ej: fecha_ej,
                            fecha_fi: fecha_fi,
                            proy_interes_1: proyectoSeleccionado.proy_interes_1,
                            proy_interes_2: proyectoSeleccionado.proy_interes_2,
                            proy_interes_3: proyectoSeleccionado.proy_interes_3,
                            proy_interes_4: proyectoSeleccionado.proy_interes_4,
                            descripcion: proyectoSeleccionado.descripcion

                        }
                    }
                    return proyecto
                })
                
                setData(dataNueva)
                abrirCerrarModalEdit()
                mostrarAlertaEditCorrecto()
                setTimeout(() =>  {window.location.reload(false)}, 2000);
            }else{
                console.error("Error en la respuesta: ",response.status)
            }
        }catch(error){
            console.error("Error al editar proyecto: ", error)
            console.log(error.request.status)
        }
    }

    const bodyEdit = (
        <div className={classes.modal}>
            <h3>Editar Proyecto</h3>
            <TextField name='titulo_proyecto' size='small'className={classes.inputMaterial} variant='standard' label='Titulo Proyecto' onChange={handleChange} value={proyectoSeleccionado && proyectoSeleccionado.titulo_proyecto} margin='normal'/>
            <br></br>
            <TextField name='subtitulo_proyecto' className={classes.inputMaterial} variant='standard' label='Subtitulo Proyecto' onChange={handleChange} value={proyectoSeleccionado && proyectoSeleccionado.subtitulo_proyecto} margin='normal'/>
            <br></br>
            <TextField 
                name='status' 
                className={classes.inputMaterial} 
                label='Status' 
                select
                id="outlined-required-select-currency"
                variant='standard'
                onChange={handleChange} 
                margin='normal'
                value={proyectoSeleccionado && proyectoSeleccionado.status}
            >
                {status2.map((option)=>(
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <br></br>
            <TextField name='proy_interes_1' size='small' className={classes.inputMaterial} variant='standard' label='Proyecto interes 1' onChange={handleChange} value={proyectoSeleccionado && proyectoSeleccionado.proy_interes_1} margin='normal'/>
            <br></br>
            <TextField name='proy_interes_2' size='small' className={classes.inputMaterial} variant='standard' label='Proyecto interes 2' onChange={handleChange} value={proyectoSeleccionado && proyectoSeleccionado.proy_interes_2} margin='normal'/>
            <br></br>
            <TextField name='proy_interes_3' size='small' className={classes.inputMaterial} variant='standard' label='Proyecto interes 3' onChange={handleChange} value={proyectoSeleccionado && proyectoSeleccionado.proy_interes_3} margin='normal'/>
            <br></br>
            <TextField name='proy_interes_4' size='small' className={classes.inputMaterial} variant='standard' label='Proyecto interes 4' onChange={handleChange} value={proyectoSeleccionado && proyectoSeleccionado.proy_interes_4} margin='normal'/>
            <br></br>
            <TextField 
                name='visible'
                className={classes.inputMaterial} 
                label='Visible' 
                select
                id="outlined-required-select-currency"
                variant='standard'
                onChange={handleChange} 
                margin='normal'
                value={proyectoSeleccionado && proyectoSeleccionado.visible}
            >
                {visible2.map((option)=>(
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}

            </TextField>
            <br></br>
            <TextField
                name="fecha"
                type="date"
                label="Fecha"
                className={classes.inputMaterial}
                variant="standard"
                onChange={handleFechaChange}
                value={proyectoSeleccionado.status === 'EJ' ? proyectoSeleccionado.fecha_ej : proyectoSeleccionado.fecha_fi}
                margin="normal"
            />
            <br></br>
            <TextField name='descripcion' id="outlined-require-multiline-static" multiline maxRows={6} className={classes.inputMaterial} variant='standard' label='Descripcion' onChange={handleChange} value={proyectoSeleccionado && proyectoSeleccionado.descripcion} margin='normal'/>
            <br></br>
            <div align = 'right'>
                <LoadingButton onClick={()=>editarProyecto()}>{loading?<CircularProgress size={24}/>:'Editar Proyecto'}</LoadingButton>
                <Button onClick={()=>abrirCerrarModalEdit()}>Cancelar</Button>
            </div>

        </div>
    )



    if (!data) {
        return <div>Cargando...</div>;
    }
    console.log(proyectoSeleccionado)
    console.log(cliente)
    return (
        <div>
            <Container maxWidth = 'xl' sx = {{marginTop:8}}>
                <NavBarAdm/>
            </Container>
            <Container sx = {{marginTop:8}} maxWidth = 'lg'>
                
                <h1 className = { classes.text3 }>
                    <Typography variant="h3" align="center" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}} mb={6}>
                        Proyecto {data[0].id_proyecto}
                    </Typography>   
                </h1>

                <div align='center'>
                    <Box
                        marginRight={'5%'}
                        marginLeft={'5%'}
                    >
                        <Grid container sx = {{align: 'center'}} spacing={2}>
                            {/**Titulo proyecto */}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Titulo proyecto: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={data[0].titulo_proyecto}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            {/**Subtitulo proyecto */}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Subtitulo proyecto: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={data[0].subtitulo_proyecto}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            {/**Proyecto padre */}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold',fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Proyecto Padre: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={data[0].num_proyecto_padre}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            {/** Proyecto Origen */}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Proyecto Origen: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={data[0].num_proyecto_origen}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            {/**ID cliente */}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Cliente: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    //value={data[0].id_cliente}
                                    value={cliente.nombre}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            {/**Fecha agendamiento*/}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Fecha Agendamiento: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={getDate(data[0].fecha_ag)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            {/**Fecha ejecucuion*/}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Fecha Ejecucion: 
                                </Typography>
                            </Grid>
                            
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={(data[0].fecha_ej) ? getDate(data[0].fecha_ej):''}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            {/**Fecha finalizado*/}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Fecha Finalizacion: 
                                </Typography>
                            </Grid>
                            
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={(data[0].fecha_fi) ? getDate(data[0].fecha_fi):''}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            {/**Status*/}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Status: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={status(data[0].status)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            {/**Proyecto de interes */}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Proyectos de interes: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={2}>
                                <TextField
                                    value={data[0].proy_interes_1}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid xs={12} sm={2}>
                                <TextField
                                    value={data[0].proy_interes_2}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid xs={12} sm={2}>
                                <TextField
                                    value={data[0].proy_interes_3}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid xs={12} sm={2}>
                                <TextField
                                    value={data[0].proy_interes_4}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            {/**Proyecto de interes 
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Proyecto de interes: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={data[0].proy_interes_2}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            */}

                            {/**Proyecto de interes 
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Proyecto de interes: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={data[0].proy_interes_3}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                                */}
                            {/**Proyecto de interes 
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Proyecto de interes: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={data[0].proy_interes_4}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                                */}
                            {/**Visible*/}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Visible: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={visible(data[0].visible)}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            {/**Descripcion */}
                            <Grid xs={12} sm={4}>
                                <Typography
                                variant="h5" 
                                mt={4} 
                                sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}
                                >
                                    Descripcion: 
                                </Typography>
                            </Grid>
                            <Grid xs={12} sm={8}>
                                <TextField
                                    value={data[0].descripcion}
                                    InputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    maxRows={6}
                                />
                            </Grid>

                            <Grid xs={12} sm={4}>
                                <Box
                                    marginTop={5}
                                    marginLeft={'30%'}
                                    marginRight={'30%'}
                                    
                                >
                                    <Button 
                                        className={classes.button}
                                        type = "button"
                                        marginTop = {10}
                                        size="large"
                                        fullWidth
                                        color='primary'
                                        variant="contained"
                                        onClick={() => abrirCerrarModalEdit()}
                                        
                                    >
                                        <Typography
                                          sx={{ fontFamily: 'Open Sans, sans-serif' }}
                                        >
                                            editar
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={4}>
                                <Box
                                    marginTop={5}
                                    marginLeft={'30%'}
                                    marginRight={'30%'}
                                    
                                >
                                    <Button 
                                        className={classes.button}
                                        type = "button"
                                        marginTop = {10}
                                        size="large"
                                        fullWidth
                                        variant="contained"
                                        color='primary'
                                        onClick={() => handleProjectImages(data[0].id_proyecto)}
                                        sx={{ fontFamily: 'Open Sans, sans-serif' }}
                                    >
                                        <Typography
                                            sx={{ fontFamily: 'Open Sans, sans-serif' }}
                                        >
                                            Ver Fotos
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={4}>
                                <Box
                                    marginTop={5}
                                    marginLeft={'30%'}
                                    marginRight={'30%'}
                                    
                                >
                                    <Button 
                                        className={classes.button}
                                        type = "button"
                                        marginTop = {10}
                                        size="large"
                                        fullWidth
                                        variant="contained"
                                        href="/proyectos"
                                        color='primary'
                                        sx={{ fontFamily: 'Open Sans, sans-serif' }}
                                    >
                                        <Typography
                                            sx={{ fontFamily: 'Open Sans, sans-serif' }}
                                        >
                                            Atras
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                            
                        </Grid>
                    </Box>
                    <Modal
                    open = {modalEdit}
                    onClose = {abrirCerrarModalEdit}
                    >
                        {bodyEdit}
                    </Modal>
                </div>
                <br></br>
                <br></br>
            </Container>
        
        {/* Mostrar más detalles del proyecto */}
        </div>
    );
}

