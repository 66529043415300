import React , {useState, useEffect} from "react";
import { Box, Button, Grid, TextField, makeStyles } from "@material-ui/core";
import NavBarAdm from "../../components/NavBarAdm";
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Tooltip, Modal, Backdrop } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from 'axios'
import {Swiper, SwiperSlide} from "swiper/react"
import { Pagination, Navigation, Autoplay, Mousewheel, Keyboard } from "swiper/modules"
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PublishIcon from '@mui/icons-material/Publish';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import swal from 'sweetalert'

const useStyles = makeStyles(theme=>({
  
    root:{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100v'

        
    },
    container:{
        opacity: '0.8',
        height: '80%', //largo del contenedor
        marginTop: theme.spacing(10), //altura del contenedor
        [theme.breakpoints.down(400 + theme.spacing(4)+4)]:{ //responsive
            marginTop: 0,
            width: '100%',
            height: '100%'
        }
    },
    div:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    avatar:{
        margin: theme.spacing(6),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
       
    },
    form:{
        width: '100%',
        marginTop: theme.spacing(1)
    },
    button:{
        margin: theme.spacing(3, 0, 2) //mrgen general
        
    },

    text:{
        marginLeft: 155,
        marginTop: 30
    },
    text2:{
        marginLeft: 128,
        marginTop: 25
    },
    text3:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(6)
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#000443",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
export default function ImgProyectos(){
    const classes = useStyles()
    const history = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [imgData, setDataImage] = useState([])
    const { projectId } = useParams()
    const [openModalIndex, setOpenModalIndex] = useState(-1)
    const [openModalImg, setOpenModalImg] = useState(-1)

    console.log(projectId)
   
    /*const axiosClient = axios.create({
        baseURL:'http://localhost:4000'
    })*/
   
    const axiosClient = axios.create({
        baseURL:'https://zeroth.cl:4000'
    })

    const mostrarAlertaEditCorrecto = () =>{
        swal({
            title:'Editado correctamente!',
            icon:'success'
        })
    }
    const mostrarAlertaEditIncorrecto = () =>{
        swal({
            title:'Actualizacion Fallida',
            icon:'error'
        })
    }
    const mostarAlertaIncorrectSize = (width, height) =>{
        swal({
            title:'Tamaño Incorrecto',
            text:`El tamaño de la imagen no corresponde. El tamaño permitido (${width}x${height}).`,
            icon:'error'
        })
    }
    const mostrarAlertaNombreImagen = () =>{
        swal({
            title:'Nombre Incorrecto',
            text:'El nombre del archivo no contiene una letra válida para determinar el tamaño.',
            icon:'error'
        })
    }
    // Maneja el cambio de la imagen seleccionada
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      setSelectedImage(file);
    };

    const checkImageSize = (imageFile, width, height) => {
        return new Promise((resolve, reject) =>{
            const img = new Image()
            img.src = URL.createObjectURL(imageFile)
            img.onload = () => {
                if(img.width === width && img.height === height) {
                    resolve(true)
                }else{
                    resolve(false)
                }
            }
        })
    }
    useEffect(() => {
        const fetchImages = async () => {
            try {
            //const response = await axios.get(`http://localhost:4000/api/proyectos/getimagenes/${projectId}`);
            const response = await axiosClient.get(`/api/proyectos/getimagenes/${projectId}`);
            const imageList = response.data;
            setDataImage(imageList); // Actualiza el estado con la lista de imágenes
            } catch (error) {
            console.error(error);
            }
        };
    
        fetchImages(); // Llama a la función para obtener imágenes cuando el componente se monta
    }, [projectId]); // Asegúrate de que la función se llame cuando projectId cambie
    
    console.log(imgData)

    const sendHandler = async(imgName) =>{
        if(!selectedImage){
            alert('Debes selecconar una imagen')
            return
        }

        let width, height;
        const fileName = selectedImage.name.toLowerCase()
        console.log(selectedImage)

        if (imgName.includes('b')){
            width = 1170
            height = 620
        } else if (imgName.includes('c') || imgName.includes('d')){
            width = 585
            height = 585
        }else{
            mostrarAlertaNombreImagen()
            //alert('El nombre del archivo no contiene una letra válida para determinar el tamaño.')
        }
        const isSizeValid = await checkImageSize(selectedImage, width, height)
        if(!isSizeValid){
            mostarAlertaIncorrectSize(width,height)
            
            return
        } 

        const formdata = new FormData()
        formdata.append('image', selectedImage)
        formdata.append('id_proyecto', projectId)
        formdata.append('titulo', imgName)
        for (let pair of formdata.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }
        try {
            //const response = await axiosClient.post(`/api/proyectos/addimagenes/${projectId}`, formdata);
            const response = await axiosClient.put(`/api/proyectos/updateimg/${projectId}`,formdata,{
                headers:{
                    'Content-Type':'multipart/form-data'
                }
            })
            if(response.status === 200){
                console.log(response.data); // manejar la respuesta del servidor
                handleCloseModal()// Cierra el modal después de enviar la imagen
                setTimeout(() =>  {window.location.reload(false)}, 2000);
                mostrarAlertaEditCorrecto()
            }else{
                console.error('Error en la respuesta: ', response.status)
                mostrarAlertaEditIncorrecto()
            }

        } catch (err) {
            console.error(err);
        }
        
        document.getElementById('fileinput').value = null
        setSelectedImage(null)
    }

    const handleProjectDetails = (projectId) => {
        // Cambia la ruta a la página de detalles del proyecto
        history(`/proyectos/getproyectos/${projectId}`);
    };

  
    function obtenerMedidas(image) {
        // Verificar que 'image' y 'image.title' estén definidos
        if (image && image.title) {
          if (image.title.includes('b')) {
            return '1170x620';
          } else if (image.title.includes('c') || image.title.includes('d')) {
            return '585x585';
          } else {
            return 'defaultSize'; // Puedes definir un tamaño por defecto si es necesario
          }
        }
        // Devolver un valor predeterminado si 'image' o 'image.title' no están definidos
        return 'defaultSize';
    }

    const handleOpenModal = (index) =>{
        setOpenModalIndex(index)
    }
    const handleCloseModal = () => {
        setOpenModalIndex(-1)
    }
    
    const handleOpenModalImg = (index) =>{
        setOpenModalImg(index)
    }
    const handleCloseModalImg = () =>{
        setOpenModalImg(-1)
    }

    return(
        <div>
            <Container sx = {{marginTop:2, marginBottom:4}} maxWidth = 'xl'>
                <NavBarAdm/>
               
                <Typography variant="h3" align="center" sx = {{fontFamily: "Open Sans, sans-serif", mt:'100px'}} mb={6}>
                    Imagenes Proyecto {projectId}
                </Typography>   
             
                <TableContainer component={Paper} style={{ marginBottom: '100px' }} >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell
                                    align="center"
                                    sx={{
                                        fontWeight: "bold",
                                        fontFamily: "Open Sans, sans-serif",
                                    }}                                   
                                >
                                    Nombre Imagen
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{
                                        fontWeight: "bold",
                                        fontFamily: "Open Sans, sans-serif",
                                    }}                                   
                                >
                                    Tamaño (px)
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{
                                    fontWeight: "bold",
                                    fontFamily: "Open Sans, sans-serif",
                                    }}                                   
                                >
                                    Acciones
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {imgData.map((imagen, index) => (
                                
                                <StyledTableRow key={index}>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontFamily: "Open Sans, sans-serif" }}                                        
                                    >
                                        {imagen.title}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontFamily: "Open Sans, sans-serif" }}                                        
                                    >
                                        {obtenerMedidas(imagen)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Tooltip
                                            title={
                                                <React.Fragment>
                                                    Actualizar Imagen
                                                </React.Fragment>
                                            }
                                        >
                                            <Button onClick={() => handleOpenModal(index)}>
                                                <PublishIcon/>
                                            </Button>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                <React.Fragment>
                                                    Ver Imagen
                                                </React.Fragment>
                                            }
                                        >
                                            <Button onClick={() => handleOpenModalImg(index)}>
                                                <FileOpenIcon/>
                                            </Button>
                                        </Tooltip>
                                        <Modal
                                            open = {openModalIndex === index}
                                            onClose={handleCloseModal}
                                            className={classes.modal}
                                        >
                                            <div className={classes.paper}>
                                                <Typography  variant='h5'align='center'sx={{ fontFamily: "Open Sans, sans-serif" }}>Actualizar Imagen {imagen.title}</Typography>
                                                <TextField
                                                    type="file"
                                                    id="fileinput"
                                                    onChange={handleImageChange}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    margin="normal"
                                                    inputProps={{
                                                        accept: 'image/*', // Limita la selección a archivos de imagen
                                                    }}
                                                />
                                                <Button variant="contained" size="medium" fullWidth onClick={()=>sendHandler(imagen.title)}>Subir</Button>
                                            </div>
                                        </Modal>
                                        <Modal
                                            open = {openModalImg === index}
                                            onClose={handleCloseModalImg}
                                            className={classes.modal}
                                        > 
                                            <img src={imagen.dataUrl} alt="Imagen"/>
                                        </Modal>
                                    </StyledTableCell>
                                </StyledTableRow>
                                    
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        className={classes.button}
                        type="button"
                        size="large"
                        variant="contained"
                        color='primary'
                        onClick={() => handleProjectDetails(projectId)}
                    >
                        Atras
                    </Button>
                </div>
            </Container>
        </div>
    );
}

