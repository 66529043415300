import React, { useState, useEffect } from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import NavBar from "../../components/NavBar";
import axios from 'axios';
import { Button, Container, Typography } from "@mui/material";
import divider from '../../assets/divider_01.png' 
import Timeline from '@mui/lab/Timeline';

import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme=>({
  
    text:{
        textAlign : 'center',
        marginTop: theme.spacing(15),
        
    },
    
    
}))

const years = [
    {
        value:'2023',
        label:'2023'
    },
    {
        value:'2018',
        label:'2018'
    },
    {
        value:'2017',
        label:'2017'
    },
    {
        value:'2016',
        label:'2016'
    },
    {
        value:'2015',
        label:'2015'
    },
    {
        value:'2014',
        label:'2014'
    },
    {
        value:'2013',
        label:'2013'
    },
    {
        value:'2012',
        label:'2012'
    },
    {
        value:'2011',
        label:'2011'
    },
    {
        value:'2010',
        label:'2010'
    },
    {
        value:'2009',
        label:'2009'
    },
    {
        value:'2008',
        label:'2008'
    }
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#000443',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));


export default function Experiencia(){
    const classes = useStyles()
    const [proyectos, setProyectos] = useState([])
    const [anio, setAnioSeleccionado] = useState(years[0].value)
    const [proyectosAnio, setProyectosAnios] = useState([])
    const history = useNavigate();

    /*const getProyectos = async() =>{
        await axios.get('http://localhost:4000/api/proyectos/getproyectosclientes')
        .then(response =>{
            console.log(response.data)
            setProyectos(response.data)
        })
    }
    useEffect(()=>{
        getProyectos()
    },[])*/

    /*const axiosClient = axios.create({
        baseURL:'http://localhost:4000'
    })*/

    const axiosClient = axios.create({
        baseURL:'https://zeroth.cl:4000'
    })

    const handleProjectDetails = (projectId) => {
        // Cambia la ruta a la página de detalles del proyecto
        console.log(projectId)
        history(`/getdetalleexperiencia/${projectId}`);
    };
 
    const getProjectsYear = async(selectedYear) =>{
        await axiosClient.get('/api/proyectos/getproyectosclientes/'+anio)
        .then(response => {
            setProyectosAnios(response.data)
            setAnioSeleccionado(() => selectedYear)
            console.log(anio)
        })
    }
    useEffect(()=>{
        getProjectsYear(anio);
    },[anio])
    
    return(
        <div>
            <Container sx = {{marginTop:8}} maxWidth = 'xl'>
                <NavBar/>
                <br></br>
                <Grid container spacing={12} style={{ marginTop: '60px' }}>
                    <Grid  xs={12} sm={4} style={{ marginLeft: '-40px', marginTop: '30px' }}>
                        
                        <Timeline 
                            /*sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                                },
                                marginTop:16
                            }}*/
                            
                        >
                            {years.map((year) =>(
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Button style={{ position: "relative", top: "-10%", color: '#000443' }} value = {year.value}  onClick={() => getProjectsYear(year.value)}>{year.label}</Button>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                        
                        
                    </Grid>
                    <Grid xs={12} sm={8} align='center'>
                        <Typography variant="h4" align="center" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Proyectos del año {anio}</Typography>
                        <br></br>
                        <TableContainer component={Paper}>
                            <Table size="small" >
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell align='left'sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Nombre Proyecto</StyledTableCell>
                                    <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Codigo Proyecto</StyledTableCell>
                                    <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Cliente</StyledTableCell>
                                    <StyledTableCell align='center' sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}}>Accion</StyledTableCell>

                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {proyectosAnio.map((proyecto) => (
                                        <StyledTableRow>
                                            <StyledTableCell align='left' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{proyecto.titulo_proyecto}</StyledTableCell>
                                            <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{proyecto.id_proyecto}</StyledTableCell>
                                            <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>{proyecto.nombre_cliente}</StyledTableCell>
                                            <StyledTableCell align='center' sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                                <Button onClick={() => handleProjectDetails(proyecto.id_proyecto)} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                                    Ver Detalles 
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                
                    </Grid>

                </Grid>
            </Container>
            
        </div>
    );
}


