import React from 'react'
import { MapContainer, TileLayer, Marker} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import Markers from '../Markers'
/**https://wiki.openstreetmap.org/wiki/ES:Teselas tipos mapas */

export default function MapView(){
    return(
        
        <MapContainer center={{lat:'-36',lng:'-70.66'}} zoom = {4} scrollWheelZoom={false}>{/**Coordenadas desde donde partira el mapa, en este caso Chile*/}
            <TileLayer 
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}.png"
            />
            <Markers/>
        </MapContainer>
        
    )
}