import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Typography } from "@material-ui/core";

export default function PopupDialog({ open, handleClose}){
    return(
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <ul>
                        <li>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>1. NCh432.n2010:</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Open Sans, sans-serif' }}>Calculamos la velocidad básica del viento según las directrices del Instituto Nacional de Normalización para asegurar la estabilidad de nuestras torres bajo diversas condiciones climáticas.</Typography>
                        </li>
                        <br></br>
                        <li>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>2. ANSI/TIA-222-G:</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Open Sans, sans-serif' }}>Evaluamos el área expuesta al viento y los factores de forma de la estructura, así como la categoría de exposición y topografía del emplazamiento, asegurando una instalación óptima y segura.</Typography>
                        </li>
                        <br></br>
                        <li>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>3. ANSI/AISC 360-10:</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Open Sans, sans-serif' }}>Nos adherimos a las especificaciones del American Institute of Steel Construction para garantizar la integridad estructural de nuestras construcciones de acero.</Typography>
                        </li>
                        <br></br>
                        <li>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>4. ASCE 7-02:</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Open Sans, sans-serif' }}>Cumplimos con los requisitos mínimos de cargas establecidos por la American Society of Civil Engineers, asegurando que nuestras estructuras soporten las cargas previstas.</Typography>
                        </li>
                        <br></br>
                        <li>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>5. ACI 318-08:</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Open Sans, sans-serif' }}>Seguimos los criterios de diseño estructural del American Concrete Institute, garantizando la calidad y seguridad del hormigón utilizado.</Typography>
                        </li>
                        <br></br>
                        <li>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>6. NCh 2369 Of.2003:</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Open Sans, sans-serif' }}>Implementamos un diseño sísmico robusto para estructuras industriales, asegurando resistencia frente a movimientos sísmicos.</Typography>
                        </li>
                        <br></br>
                        <li>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>7. NCh 3171 Of 2017:</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Open Sans, sans-serif' }}> Aplicamos disposiciones generales y combinaciones de carga para asegurar una construcción equilibrada y segura.</Typography>
                        </li>
                        <br></br>
                        <li>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>8. NCh 427/1 Of 2016:</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Open Sans, sans-serif' }}>Cumplimos con los requisitos para el cálculo de estructuras de acero para edificios, asegurando la máxima resistencia y durabilidad.</Typography>
                        </li>
                        <br></br>
                        <li>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>9. NCh 170 Of 2016 y NCh 430 Of 2008:</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Open Sans, sans-serif' }}>Garantizamos la calidad del hormigón armado utilizado en nuestras estructuras, siguiendo requisitos de diseño y cálculo estrictos.</Typography>
                        </li>
                        <br></br>
                        <li>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>10. NCh 428 Of 2017:</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Open Sans, sans-serif' }}>Aseguramos la correcta ejecución de construcción de acero, cumpliendo con las normas vigentes.</Typography>
                        </li>
                        <br></br>
                        <li>
                            <Typography variant='h6' sx={{ fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif' }}>11. ASTM:</Typography>
                            <Typography variant='body1' sx={{ fontFamily: 'Open Sans, sans-serif' }}>Adherimos a los estándares de la American Society for Testing and Materials para asegurar la calidad de los materiales utilizados.</Typography>
                        </li>
                        
                    </ul>
                </DialogContent>

            </Dialog>
        </div>
    )
}