import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

const app = firebase.initializeApp({
    apiKey: "AIzaSyB2Hvw-5jsTpssCuIPbZWai7hwrXBwjVNE",
    authDomain: "webdesamd-b4e8f.firebaseapp.com",
    projectId: "webdesamd-b4e8f",
    storageBucket: "webdesamd-b4e8f.appspot.com",
    messagingSenderId: "97235806682",
    appId: "1:97235806682:web:a36811f0df0e550a5678e3"
})


/**const app = firebase.initializeApp({
    apiKey: "AIzaSyAZVzoT976byHHRyW3eJevurqG9jp85uvQ",
    authDomain: "controlacceso-ee1ac.firebaseapp.com",
    projectId: "controlacceso-ee1ac",
    storageBucket: "controlacceso-ee1ac.appspot.com",
    messagingSenderId: "926118710386",
    appId: "1:926118710386:web:87ebc2d08f10dffa9906eb"
}) */

/**
 * la autenticacion la pueda ultizar en cualquer parte de mi app, la exporto asi para que se importe con el mismo nombre auth
 * 
*/
export const auth = firebase.auth();
export default app;