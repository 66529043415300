import React from 'react'
import { 
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    
} from '@material-ui/core'
import { useState } from "react";

import { useAuth } from '../../context/AuthContext';
import { Box } from '@mui/system'
import { Button, ListItemButton } from '@mui/material';

export default function NavListDrawerAdm({navLink}) {
    const { logout } = useAuth(); //esta funcion viene de /context/AuthContext
    const [error, setError] = useState ('');
    const handleLogout = async () => {
        try{
            await logout();
        } catch (error){ 
            setError('Server Error')
        }
    }
    return (
        <Box sx={{width:250}}>
            <nav>
                <List>
                    {
                        navLink.map(item =>(
                            <ListItem disablePadding key = {item.title}>
                                <ListItemButton
                                    component = 'a'
                                    href = {item.path}
                                >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText>{item.title}</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button onClick = {handleLogout}>
                            Salir
                        </Button>
                    </div>

                </List>
            </nav>
            <Divider/>
        </Box>
    )
}