import React, { useState } from "react";
import { Grid, makeStyles, Button } from "@material-ui/core";
import NavBar from "../../components/NavBar";
import { Container, Typography } from "@mui/material";
import divider from '../../assets/divider_01.png' 
import {Swiper, SwiperSlide} from "swiper/react"
import PopupDialog from "../../components/PopUpDialog";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, } from "swiper/modules"

import image1 from '../../assets/suppliespage/icon_1.png'
import image2 from '../../assets/suppliespage/icon_2.png'
import image3 from '../../assets/suppliespage/icon_3.png'

 
const useStyles = makeStyles(theme=>({
  
    text:{
        textAlign : 'center',
        marginTop: theme.spacing(10),
        
    },
    text2:{
        textAlign : 'center',
        marginTop: theme.spacing(3),
        padding: '10px 20px',
        color: 'white'
    },
    imgn:{
        align: 'center',
        marginTop: theme.spacing(8)
    },
    text3:{
        textAlign: 'center',
        marginBottom: theme.spacing(15)
    },

    imgn2:{
        width: "360px"
    },
    textButton:{
       color:'#202020',
       '&active':{
        color: 'primary'
       }


    },
    footer: {
        backgroundColor: '#333', // Color de fondo del footer
        color: '#fff', // Color del texto del footer
        padding: theme.spacing(2), // Espaciado interno del footer
        textAlign: 'center', // Alineación del texto en el centro
        marginTop: 'auto', // Coloca el footer al final de la página
    },
    
    
}))

export default function Suministros(){
    const classes = useStyles()
    const txt1 = "Estructuras, Obras Civiles, Montaje"
    const words1 = txt1.split(',')
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const handleOpen = () => setIsDialogOpen(true);
    const handleClose = () => setIsDialogOpen(false);
    return(
        <div>
            <Container sx = {{marginTop:2}} maxWidth = 'xl'>
                <NavBar/>
                <h1 className = { classes.text }>
                    <Typography variant="h4" align="center" sx = {{ fontFamily: 'Open Sans, sans-serif'}} mb={6}>
                        CONOCE NUESTRAS OPCIONES DE SUMINISTROS
                    </Typography>   
                </h1>
                <div align = 'center' >
                    <img src = {divider} />
                </div>
                <Grid container >
                    <Grid item xs = {12} sm = {4}  alignItems="center" >
                        <div align = 'center'>
                            <img src= {image1} className={classes.imgn}/>
                        
                            <Typography variant="h5" align="center" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}} mt={2} mb={2}>
                                
                                INFRAESTRUCTURA
                                
                            </Typography>
                            
                            <Typography variant="body1" className = { classes.text3 } mb = {6} mr={4} ml={4}  paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Nos especializamos en la instalación de mástiles equipados con instrumentación meteorológica de la más alta calidad. Nos comprometemos a cumplir con los más estrictos estándares internacionales y nacionales, garantizando la durabilidad, seguridad y eficacia de nuestras estructuras.
                            </Typography>
                            
                        </div>
                    </Grid>

                    <Grid item xs = {12} sm = {4} alignItems="center">
                        <div align = 'center'>
                            <img src= {image2} className={classes.imgn}/>
                            <Typography variant="h5" align="center" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}} mt={2} mb={2}>
                                EQUIPAMIENTO
                            </Typography >
                            <Typography variant="body1" className = { classes.text3 } mb = {10} mr={4} ml={4}  paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                Disponemos de una amplia gama de sensores y equipos para evaluación de potencial Eólico y Solar. Incorporamos la detección remota con unidades Lidar y Sodar, equipados con sistemas de suministro autónomo de energía.
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs = {12} sm = {4} alignItems="center">
                        <div align = 'center'>
                            <img src= {image3} className={classes.imgn}/>
                            <Typography variant="h5" align="center" sx = {{fontWeight: 'bold', fontFamily: 'Open Sans, sans-serif'}} mt={2} mb={2}>
                                SERVICIOS
                            </Typography>
                            <Typography variant="body1" className = { classes.text3 } mb = {15} mr={4} ml={4}  paragraph = {true} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                                Ofrecemos servicios de mantención preventiva y correctiva de instalaciones propia o de terceros. Incluimos el servicio de inspección técnica y asesoría en la implementación y operación de campañas de evaluación de potencial eólico y Solar.
                            </Typography>
                        </div>
                    </Grid>

                </Grid>
                <div align = 'center'>
                    <Button onClick={handleOpen} variant="contained" style= {{marginBottom: 30}}>
                        <Typography sx={{ fontWeight: 'bold',fontFamily: 'Open Sans, sans-serif' }}>Normas y Estándares de Calidad</Typography>
                    </Button>
                    <PopupDialog open={isDialogOpen} handleClose={handleClose}/>
                </div>

                <Swiper
                    align = 'center'
                    centeredSlides = {true}
                    slidesPerView={'auto'}
                    autoplay = {{
                        delay: 4000,
                        disableOnInteraction: false
                    }}
                    modules={[Autoplay]}
                >
                    <SwiperSlide>
                        <Typography variant="h5" mb = {7} mt={4}  align="center" paragraph = {true} color={'grey'} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Cada campaña de evaluación es implementada con sensores y accesorios
                            bajo la norma europea.
                            Cumplimos con los más altos estándares de trabajo.
                            Asegurando la correcta instalación y calidad de los datos registrados en cada sitio.
                        </Typography>
                    </SwiperSlide>

                    <SwiperSlide>
                        <Typography variant="h5" mb = {7} mt={4}  align="center" paragraph = {true} color={'grey'} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Diseñamos, Suministramos e Instalamos estructuras para estaciones meteorológicas y torres reticuladas arriostradas.
                        </Typography>
                    </SwiperSlide>
                    
                    <SwiperSlide>
                        <Typography variant="h5" mb = {5} mt={4}  align="center" paragraph = {true} color={'grey'} sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                            Nuestros servicios permiten dar continuidad a la recolección de datos, para así, minimizar la perdida de registros.
                        </Typography>
                    </SwiperSlide>
                </Swiper>

                <div align = 'center' style={{marginTop: '15px', marginBottom:'15px'}} >
                    <img src = {divider} />
                </div>
            </Container>
            <footer className={classes.footer}>
                <Typography variant='body2' sx = {{fontFamily: 'Open Sans, sans-serif'}}>© 2024 Desamd. Todos los derechos reservados.</Typography>
            </footer>
            
        </div>
    );
}